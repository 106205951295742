// Animation
export const isPageAnimationEnabled = true;


// Login Page Style
export const isDefaultStyle = true; // true or false


//Images
export const logoPath = "logo.png";


//Pendo Analytics
export const pendoKey = '055e0a8b-b58d-43b8-6bc3-991ad9ea3974';
export const studyURL = "victorion2prevent.longboat.com";
export const sponsorName = "Novartis";
export const studyId = "NOVA278";
export const AppType = "CN";
export const pendoAccountName = studyId + '_' + AppType;


// Authentication
export const password = "Victorion2P!#"
export const passwordReview = "Victorion2P!#Rev"
export const cookieExpiryTimeInMins = 10


// i18n localisation configuration
export const defaultLocale = "en";
export const supportedLocales = [
    "en",
    "af",
    "bd",
    "bf",
    "bg",
    "cs",
    "ct",
    "da",
    "hu",
    "ko",
    "lp",
    "lt",
    "lv",
    "ms",
    "pl",
    "ro",
    "ru",
    "sl",
    "sr",
    "st",
    "sv",
    "ta",
    "th",
    "tr",
    "xh",
    "zh",
    "zu"
];