<template>
<div id="js-body-wrap" class="body-wrap">
    <!-- 'animate' class -->
    <header class="options-wrap">
        <ui-button class="logout-btn logout-btn--bordered" @click="logout">
            {{ $t("log_out_btn") }}
        </ui-button>
        <lang-dropdown :i18nMode="$i18n.locale" :locales="locales" class="lang-dropdown" @localeChange="handleLocaleChangeEvent" ></lang-dropdown>
    </header>

    <main>
        <div class="banner">
            <div class="banner__image-wrapper">
                <img class="banner__image" :src="require(`@/assets/images/${logoSrc}`)" alt="logo" />
            </div>
            <div class="banner__text-wrapper">
                <div>
                    <h4 class="banner__text-heading" v-html="$t('welcome_text_heading')"></h4>
                </div>
                <div>
                    <p class="banner__text" v-html="$t('welcome_text')"></p>
                </div>
            </div>
        </div>

        <div id="js-video-wrapper" class="video-wrapper">
            <slot></slot>
            <p class="extra-text" v-if="isExtraTextAvailable()" v-html="$t('welcome_text_alt')"></p>
        </div>
    </main>

    <footer>
        <copyright-footer class="consent-copyright" :locale="$i18n.locale"></copyright-footer>
    </footer>
</div>
</template>

<script>
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import UiButton from "@/components/UiButton";
import auth from "@/plugins/auth";
import utils from "@/common/utils";
import CopyRightLabel from "@/components/CopyRightLabel.vue";
import {
    logoPath
} from "@/config";
import {
    getpreferredLocales,setCurrentLocale
} from "@/plugins/i18n";

export default {
    components: {
        "lang-dropdown": LocaleSwitcher,
        "ui-button": UiButton,
        "copyright-footer": CopyRightLabel,
    },
    data() {
        return {
            locales: getpreferredLocales(),
            logoSrc: logoPath,
        };
    },
    mounted() {
        document.title = this.$t('site_title') ;
        utils.configureOnScrollAnimation();
        utils.addWistiaScript().then(() => {
            // do anything
        });
    },
    methods: {
        logout() {
            auth.logout(this, "pass", "passRev", "/login");
            //location.reload();
        },
        isExtraTextAvailable() {
            if ((this.$t('welcome_text_alt')) === 'welcome_text_alt')
                return false;
            else return true;
        },
        handleLocaleChangeEvent(locale){
            setCurrentLocale(locale)
            utils.handleLocaleChangeEvent(this,locale);
        }
    },
};
</script>

<style lang="scss">
@import "../assets/styles/consent.scss";
</style>
